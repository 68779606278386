:root {
  --mobile-scale: 0.49;
  --desktop-scale: 1;
  --full-width-scale: 2;
}

#mobile-warning-message {
  display: none;
}

/* Ensure mobile/tablet devices are in landscape mode */
@media only screen and (orientation: portrait) and (max-width: 999px) {
  #mobile-warning-message {
    display: block !important;
    margin: 1em;
  }

  #intro-container {
    display: none !important;
  }
}

/* Ensure intro screen and main animation shrinks in half to fit on mobile screen */
@media only screen and (orientation: landscape) and (max-height: 767px) {
  #main-narration {
    font-size: 1rem;
  }

  #loading-screen img#nuku-title-profile {
    width: 410px;
    right: 0%;
  }

  #loading-screen #intro-content-container {
    height: 345px;
    margin: auto 0;
  }

  #loading-screen .nuku-img-wrapper {
    margin-right: 0.5em;
  }

  #loading-screen .titles {
    transform: scale(var(--mobile-scale));
    transform-origin: 0% 50%;
  }

  #border-container, #top-buttons, .scroll-prompt-container {
    transform: scale(var(--mobile-scale));
    transform-origin: 0% 0%;
  }

  .top-buttons-container {
    width: calc(100vw * var(--full-width-scale));
  }
}

@media only screen and (orientation: landscape) and (max-height: 550px) {
  .voiceover-btn {
    padding: 15px;
    bottom: -37px;
  }
  .narration.bottom .voiceover-btn {
    padding: 15px;
    top: -37px;
  }
}

@media only screen and (orientation: landscape) and (max-height: 380px) {
  #top-buttons {
    transform: scale(0.5);
    width: calc(100vw * var(--full-width-scale) * (var(--mobile-scale) / 0.5));
  }
}

/* == Desktop full-size support. Mostly works but causes weird bug in s1p4 :/ */
/* @media only screen and (orientation: landscape) and (min-height: 769px) {
  #border-container, .scroll-prompt-container {
    transform: scale(var(--desktop-scale));
    transform-origin: 0% 0%;
  }
} */